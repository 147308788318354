
import { mobileCheck } from "./functions/mobile-check";
console.log(mobileCheck())


function showHint() {
    document.getElementById('hint').style.display = 'block';
}

function checkAnswer(selectedOption) {
    // Correct answer is 'b'
    if (selectedOption === 'b') {
        alert("Правильно!");
    } else {
        alert("Неправильно. Попробуйте еще раз.");
    }
    document.getElementById('hint').style.display = 'block'; // Show hint if answer is attempted
}
